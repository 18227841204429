import { render, staticRenderFns } from "./framework_badge.vue?vue&type=template&id=31845d5f"
import script from "./framework_badge.vue?vue&type=script&lang=js"
export * from "./framework_badge.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports